<template>
  <div>
    <div class="content" v-if="!show">
    <div id="box">
      <el-button icon="el-icon-plus" type="primary" @click="handleCreate">新建优惠券</el-button>
      <div class="group-btns">
        <el-tabs v-model="selected" @tab-click="toggleButton">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="满减券" name="10"></el-tab-pane>
          <el-tab-pane label="折扣券" name="20"></el-tab-pane>
          <el-tab-pane label="随机券" name="30"></el-tab-pane>
          <el-tab-pane label="礼品券" name="40"></el-tab-pane>
        </el-tabs>
      </div>

      <el-form :inline="true" :model="form">
        <el-form-item label="">
          <el-input clearable v-model="form.search" placeholder="券码/券名称/券标"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select clearable placeholder="请选择品类" v-model="form.type_seq">
            <el-option :key="item.value" v-for="item in typeList" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item clearable label="状态">
          <el-select placeholder="请选择状态" v-model="form.status" clearable>
            <el-option v-for="item in statusList" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="有效期">
          <el-date-picker v-model="form.validTime" type="datetimerange" start-placeholder="开始日期"
            format="yyyy-MM-dd HH:mm:ss" :unlink-panels="true" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="default" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      </div>
      <div class="table" >
        <el-table  stripe :height="height" class="table-box" v-loading="loading" :data="list" border :fit="true"
          style="width:100%;background:#fff">
          <el-table-column align="center" min-width="150px" label="编号" prop="sn"></el-table-column>
          <el-table-column align="left" width="150px" label="优惠券名称" prop="title_name"></el-table-column>
          <el-table-column align="center" label="券标" prop="tag_name" width="150px">
            <template slot-scope="scope">
              <span
                :style="{ 'front-size': '14px', 'color': '#fff', 'padding': '3px 5px', 'border-radius': '50px', 'background-color': scope.row.color_style }">{{ scope.row.tag_name }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="券类型" prop="type_seq" :formatter="getCurrentType"></el-table-column>
          <el-table-column align="center" label="面值/折扣" prop="type_data">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.type_seq == 10 || scope.row.type_seq == 20">
                  {{
                      scope.row.type_seq == 10
                        ? scope.row.type_data + "元"
                        : scope.row.type_seq == 20
                          ? scope.row.type_data + "折"
                          : ""
                  }}

                </div>
                <div v-else>
                  <el-popover placement="right" width="auto" trigger="hover">
                    <template v-if="scope.row.type_seq == 30">
                      <div class="list" :key="index" v-for="(item, index) in scope.row.type_data">
                        <span>最小值</span>
                        <span>{{ item.min_value }}</span>
                        <span>最大值</span>
                        <span>{{ item.max_value }}</span>
                        <span>百分比</span>
                        <span>{{ item.probability }}%</span>
                      </div>
                    </template>
                    <div v-else class="flex">
                      <el-image :preview-src-list="[scope.row.type_data.goods_pic]" :fit="'contain'"
                        :src="scope.row.type_data.goods_pic" style="width:100px;height:100px"></el-image>
                      <div class="names">
                        <span>名称:{{ scope.row.type_data.goods_name }}</span>
                        <span>价值:{{ scope.row.type_data.goods_price }}</span>
                      </div>
                    </div>
                    <el-link slot="reference" type="primary">查看</el-link>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" label="有效期" min-width="200">
            <template slot-scope="scope">
              {{ scope.row.valid_start_time.split(' ')[0] }}
              -
              {{ scope.row.valid_end_time.split(' ')[0] }}

            </template>
          </el-table-column>
          <el-table-column align="center" label="是否启用" prop="status" width="80">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" @change="status => changeSwitch(scope.row.coupon_id, status)"
                :active-value="10" :inactive-value="20"></el-switch>
            </template>
          </el-table-column>
          <el-table-column align="center"  label="券数量（发放）" prop="issue_total"></el-table-column>
          <el-table-column align="center"  label="剩余数量" prop="issue_total">
            <template slot-scope="scope">
              {{ scope.row.issue_total == 0  ? "不限" :
                    (scope.row.issue_total - scope.row.issue_tally)
                }}
            </template>
          </el-table-column>
          <el-table-column align="center"  label="已领取" prop="receive_total"></el-table-column>
          <el-table-column align="center" label="已使用" prop="have_use_num"></el-table-column>
          <el-table-column align="center" label="已过期" prop="have_overdue_num"></el-table-column>

          <el-table-column label="创建人" prop="store_username" width="100"></el-table-column>
           <el-table-column label="修改人" prop="update_username"  width="100"></el-table-column>
          <el-table-column label="创建时间" prop="create_time" width="160"></el-table-column>

          <el-table-column label="修改时间" width="160" prop="update_time">
          </el-table-column>

          <el-table-column align="center" label="操作" fixed="right" width="180">
            <template slot-scope="scope">
              <div style="display:flex;justify-content:space-around">
                <el-link v-if="scope.row.is_editor==1" type="primary" @click="() => handleEdit(scope.row)" :underline="false">编辑</el-link>
                <el-link v-if="scope.row.is_editor==0" type="primary" @click="() => handleLook(scope.row)" :underline="false">查看</el-link>
                <el-link @click="() => handleView(scope.row)">查看明细</el-link>
                <el-link type="danger" @click="() => handleDelete(scope.row)" :underline="false">删除</el-link>

              </div>
            </template>
          </el-table-column>
        </el-table>
    </div>
    <div class="pagina">
      <el-pagination background :current-page="listQuery.page" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :page-sizes="[15, 20, 50, 100, 200, 400]"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
  <addCoupon :protypeList="protypeList"  :brandList="brandList" :shopList="shopList"      :sendPassParmas="sendPassParmas" v-if="show" @onclose="handleColse" :show="show"
    :optretion_type="optretion_type" :couponItem="couponItem"></addCoupon>
  </div>
</template>
<script>
import {
  getCounponList,
  createEditCoupon,
  couponDetail,
  updateCoupon,
  deleteCoupon
} from "@/api/marketing/couponList";

import { getMerchantList } from "@/api/index";
import { getListReq } from "@/api/goods/goodsConfig/classify";

import { getBrandList } from "@/api/system/goldPrice/list";

import addCoupon from "./createCoupon.vue";
import _ from "lodash";


export default {
  components: { addCoupon },
  name: "coupnlist",
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 15
      },
      total: 0,
      selected:"0",
      form: {
        type_seq: "",
        search: "",
        status: "",
        valid_start_time: "",
        valid_end_time: "",
        validTime: ""
      },
      list: [],
       pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
      },
      sendPassParmas: {},

      typeList: [
        {
          label: "全部",
          value: 0
        },
        {
          label: "满减券",
          value: 10
        },
        {
          label: "指定折扣",
          value: 20
        },
        {
          label: "随机券",
          value: 30
        },
        {
          label: "礼品券",
          value: 40
        }
      ],
      statusList: [
        {
          label: "正常",
          value: 10
        },
        {
          label: "禁用",
          value: 20
        }
      ],
      loading: false,
      show: false,
      couponItem: {},
      optretion_type: "add",
      height:"auto",

      protypeList:[],
      brandList:[],
      shopList:[]
    };
  },

  created() {
    this.getBrand();
    this.getProtypeList();
    this.getMerchantLists()
    this.getList();
  },

  mounted(){
    this.$nextTick(()=>{
        this.getDyaicHeight();
        this.getBindEvent();
    })
  },

 activated(){
     this.getDyaicHeight();
     this.getBindEvent();
},

 deactivated(){
   window.removeEventListener('resize',this.getDyaicHeight);
 },


  activated() {
    let { query } = this.$route;
    if (query && query.isCreateCopuon) {
      this.sendPassParmas.isCreateCopuon = true;
      this.sendPassParmas.params = query.params;
      this.handleCreate();
    }
  },

  methods: {
    getBrand() {
      getBrandList().then(res => {
        if (res.code == 1) {
          this.brandList = res.data;
        }
      });
    },

    getProtypeList() {
      getListReq().then(res => {
        if (res.code == 1) {
          this.protypeList = res.data;
        }
      });
    },

    getMerchantLists() {
      getMerchantList().then(res => {
        if (res.code == 1) {
          this.shopList = res.data;
        }
      });
    },

    getBindEvent(){
      window.addEventListener('resize',this.getDyaicHeight,false)
    },

    getDyaicHeight(){
          let h=document.querySelector('#box').getBoundingClientRect().height;
          this.height=document.documentElement.clientHeight-65-40-40-h-20-11;
    },

    getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let type_seq = this.form.type_seq == "all" ? "" : this.form.type_seq;

      if (this.form.validTime) {
        this.valid_start_time = this.form.validTime[0];
        this.valid_end_time = this.form.validTime[1];
      } else {
        this.valid_start_time = "";
        this.valid_end_time = "";
      }

      let data = {
        ...this.listQuery,
        ...this.form,
        type_seq
      };
      getCounponList(data)
        .then(res => {
          if (res.code == 1) {
            this.list = res.data.list;
            this.total = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    toggleButton(val) {
      this.listQuery.page = 1;
      this.form.type_seq=parseInt(this.selected)
      this.getList();
    },

    handleCreate(row) {
      this.show = true;
      this.couponItem = {};
      this.optretion_type = "add";
    },

    handleReset() {
      let formDefault = this.$options.data().form;
      this.form = { ...formDefault };
      this.listQuery.page = 1;
      this.getList();
    },

    handleQuery() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(size) {
      this.listQuery.limit = size;
      this.getList();
    },
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.getList();
    },

    handleColse(val) {
      if (val) {
        this.listQuery.page = 1;
        this.getList();
      }
      this.show = false;
    },

    handleEdit(row) {
      this.optretion_type = "edit";
      couponDetail({ coupon_id: row.coupon_id }).then(res => {
        if (res.code == 1) {
          this.show = true;
          this.couponItem = res.data;
        }
        if (res.code == 2) {
          this.$message.error(res.msg);
        }
      });
    },

    handleLook(row){
      this.optretion_type = "view";
      couponDetail({ coupon_id: row.coupon_id }).then(res => {
        if (res.code == 1) {
          this.show = true;
          this.couponItem = res.data;
        if (res.code == 2) {
          this.$message.error(res.msg);
        }
        }
      })
    },

    handleView(row) {
      this.$router.push({
        name:"couponCount",
        params:{
          sn:row.sn
        }
      })
    },

    handleDelete(val) {
      let coupon_id = val.coupon_id;
      deleteCoupon({ coupon_id }).then(res => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.listQuery.page = 1;
          this.getList();
        }
        if (res.code == 2) {
          this.$message.error(res.msg);
        }
      });
    },
    changeSwitch(val, status) {
      console.log(status);
      updateCoupon({ coupon_id: val, status }).then(res => {
        if (res.code == 1) {
          this.getList();
        }
      });
    },

    handleErcode() { },

    getCurrentType(row) {
      return this.typeList.find(item => item.value == row.type_seq).label;
    }
  }
};
</script>
<style scoped lang="less">
.content {
  padding: 10px;
  box-sizing: border-box;
}

.table {
  background-color: #fff;
}

.group-btns {
  margin: 10px 0;
}

.table-box {
  height: calc(100vh - 400px);
}

.pagina {
  background-color: #ffff;
  height: 40px;
  line-height: 40px;
}

.flex {
  display: flex;
  align-items: center;
}

.names {
  margin-left: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.list {
  display: flex;
  margin: 20px 0;

  span {
    width: 50px;
  }
}
</style>
