import { post } from '@/utils/request';

// 查询优惠卷列表

const getCounponList=function(data){
    return post({
        url:"/store/coupon.Index/list",
        data
    })
}


// 创建编辑优惠券
const createEditCoupon=(data)=>{
    return post({
        url:"/store/coupon.Index/save",
        data
    })
}

// 优惠券详情

const couponDetail=(data)=>{
    return post({
        url:"/store/coupon.Index/details",
        data
    })
}


// 优惠券删除
const deleteCoupon=(data)=>{
    return post({
        url:"/store/coupon.Index/delete",
        data
    })
}

// 更新优惠券

const updateCoupon=(data)=>{
    return post({
        url:"/store/coupon.Index/updateStatus",
        data
    })
}



export {
    getCounponList,
    createEditCoupon,
    couponDetail,
    updateCoupon,
    deleteCoupon,
}