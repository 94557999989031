<template>
  <div>
    <div class="top">
      <el-page-header @back="goBack"
        :content="optretion_type == 'add' ? '添加优惠券' : optretion_type == 'edit' ? '编辑优惠券' : '查看'"></el-page-header>
    </div>
    <div class="main">
      <div class="left">
        <div class="phone" :style="{ backgroundImage: 'url(' + iphoneImg + ')' }">
          <div class="phone-bg"
            :style="{ backgroundColor: form.color_style, backgroundImage: 'url(' + form.background_image + ')', height: '100px' }">
            <div class="phone-money">
              <div class="flex">
                <div v-if="form.type_seq == 20 || form.type_seq == 10">￥{{ form.type_data }}</div>
                <div v-if="form.type_seq == 30">￥{{ goods_list[0].max_value }} - {{ goods_list[0].min_value }}</div>
                <div v-if="form.type_seq == 40">赠品</div>
                <div v-if="form.type_seq == 20">折</div>
              </div>
              <div>{{ user_threshold == 0 ? '无门槛' : '满' + form.use_threshold + '可用' }}</div>
            </div>
            <div class="phone-right" style="display:flex;flex-direction:column">
              <span v-if="form.title_name"> {{ form.title_name }}</span>
              <span v-if="form.tag_name">{{ form.tag_name }}</span>
              <span v-if="form.useTime && form.useTime.length > 0">有效期:{{ form.useTime && form.useTime[0].split(' ')[0]
              }}-
                {{ form.useTime && form.useTime[1].split(' ')[0] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <el-form ref="form" :model="form" :disabled="optretion_type == 'view'" label-width="150px" :rules="rules">
          <div>
            <div>优惠信息</div>
            <el-divider></el-divider>
            <el-form-item>
              <span slot="label"><i class="red">*</i>券分类</span>
              <el-radio @change="changeCategory" v-model="form.category_seq" :label="10">通用券</el-radio>
              <el-radio @change="changeCategory" v-model="form.category_seq" :label="20">人情券</el-radio>
            </el-form-item>

            <el-form-item prop="color_style">
              <span slot="label">券样式</span>
              <div style="display:flex;height:50px">
                <span
                  style="width:50px;height:50px;background:#10AD61;margin-right:20px;cursor: pointer;display: flex;justify-content:center;align-items:center;"
                  @click="() => handleColor('#10AD61')">
                  <i class="el-icon-check icon" v-if="form.color_style == '#10AD61'"></i>
                </span>

                <span
                  style="width:50px;height:50px;background:#35A4DE;margin-right:20px;cursor: pointer;display: flex;justify-content:center;align-items:center;"
                  @click="() => handleColor('#35A4DE')">
                  <i class="el-icon-check icon" v-if="form.color_style == '#35A4DE'"></i></span>

                <span
                  style="width:50px;height:50px;background:#9058CB;margin-right:20px;cursor: pointer;display: flex;justify-content:center;align-items:center;"
                  @click="() => handleColor('#9058CB')">
                  <i class="el-icon-check icon" v-if="form.color_style == '#9058CB'"></i>
                </span>

                <span
                  style="width:50px;height:50px;background:#DE98C3;margin-right:20px;cursor: pointer;display: flex;justify-content:center;align-items:center;"
                  @click="() => handleColor('#DE98C3')">
                  <i class="el-icon-check icon" v-if="form.color_style == '#DE98C3'"></i>
                </span>

                <span
                  style="width:50px;height:50px;background:#F9861F;margin-right:20px;cursor: pointer;display: flex;justify-content:center;align-items:center;"
                  @click="() => handleColor('#F9861F')">
                  <i class="el-icon-check icon" v-if="form.color_style == '#F9861F'"></i>
                </span>

                <span
                  style="width:50px;height:50px;background:#D54036;margin-right:20px;cursor: pointer;display: flex;justify-content:center;align-items:center;"
                  @click="() => handleColor('#D54036')">
                  <i class="el-icon-check icon" v-if="form.color_style == '#D54036'"></i></span>
                <span>
                  <el-image :src="form.background_image" v-if="form.background_image" style="width:50px;height:50px">
                  </el-image>
                  <el-image :src="positive" v-else style="width:50px;height:50px"></el-image>
                </span>
                <span>
                  <el-upload action="https://api.etouch.top/store/common.UploadFile/uploadOssImage" :data="{
                    directory: 'AGENT_INFO'
                  }" :headers="{ authorization: token }" :show-file-list="false"
                    :on-success="(res) => handlePicnegativeSuccess(res, 'bg')" :before-upload="beforeAvatarUpload">
                    <span
                      style="display: block;width:50px;height:50px;display:flex;justify-content: center;align-items:center;border:1px solid #eee;margin-left:20px"><i
                        class="el-icon-plus"></i></span>
                  </el-upload>
                </span>
              </div>
            </el-form-item>

            <el-form-item prop="title_name">
              <span slot="label">券名称</span>
              <el-row>
                <el-col :span="8">
                  <el-input type="input" minlength="1" maxlength="20" v-model="form.title_name" placeholder="请输入优惠券名称">
                  </el-input>
                </el-col>
                <el-col :span="5" style="margin-left:10px">
                  <span>1-20字符</span>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="tag_name">
              <span slot="label">
                自定义券标
              </span>
              <el-row>
                <el-col :span="8">
                  <el-input v-model.trim="form.tag_name" minlength="1" maxlength="6" placeholder="请输入券标"></el-input>
                </el-col>
                <el-col :span="5" style="margin-left:10px">
                  <span>1-6字符</span>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item prop="use_threshold">
              <span slot="label"><i class="red">*</i>使用门槛</span>
              <el-row>
                <el-col :span="2">
                  <el-radio @change="handleThreshold" v-model="user_threshold" :label="0">无门槛</el-radio>
                </el-col>
                <el-col :span="16">
                  <el-row :span="24">
                    <el-col :span="2">
                      <el-radio @change="handleThreshold" v-model="user_threshold" :label="1">订单满&nbsp;
                      </el-radio>
                    </el-col>
                    <el-col :span="4" style="margin-left:10px" v-if="user_threshold == 1">
                      <el-input v-model="form.use_threshold"></el-input>
                    </el-col>
                    <el-col :span="1" style="margin-left:10px" v-if="user_threshold == 1"> <span
                        v-if="user_threshold == 1">&nbsp;可用</span></el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item>
              <span slot="label"><i class="red">*</i>优惠类型</span>
              <el-radio @change="changeTypeSeq" v-model="form.type_seq" :label="10">指定金额</el-radio>
              <el-radio @change="changeTypeSeq" v-model="form.type_seq" :label="20">指定折扣</el-radio>
              <el-radio @change="changeTypeSeq" v-model="form.type_seq" :label="30">随机金额</el-radio>
              <el-radio @change="changeTypeSeq" v-model="form.type_seq" :label="40">礼品</el-radio>
            </el-form-item>

            <el-form-item v-if="form.type_seq == 10" prop="type_data">
              <span slot="label"><i class="red">*</i>
                券面值
              </span>
              <el-input-number v-model="form.type_data" :min="0"></el-input-number>
              <span class="ml-10">元（仅限标价类）</span>
            </el-form-item>

            <el-form-item prop="type_data" v-if="form.type_seq == 20">
              <span slot="label"><i class="red">*</i>折扣</span>
              <el-input-number :controls="false" v-model="form.type_data" :min="0"></el-input-number>
              <span>折</span>
            </el-form-item>


            <el-form-item v-if="form.type_seq == 30">
              <span slot="label"><i class="red">*</i>券面值</span>
              <div class="list" :key="index" v-for="(item, index) in  goods_list">
                <div class="money-area">
                  <el-input-number :controls="false" v-model="item.min_value" :min="0"></el-input-number>-
                  <el-input-number :controls="false" v-model="item.max_value" :min="0"></el-input-number>
                  <span>&nbsp;元</span>
                </div>
                <div class="precter flex">
                  <span>占比&nbsp;</span>
                  <el-input-number :controls="false" v-model="item.probability" :min="0"></el-input-number>
                  <span>%</span>
                </div>
                <div class="opeater">
                  <el-link type="danger" @click="(index) => handleDelete(index)" style="margin:0 5px" v-if="index != 0">
                    删除
                  </el-link>
                  <el-link type="primary" @click="handleAdd" style="margin:0 5px">添加</el-link>
                </div>
              </div>
            </el-form-item>

            <el-form-item v-if="form.type_seq == 40">
              <span slot="label"><i class="red">*</i>礼品</span>
              <div class="gift">
                <el-upload action="https://api.etouch.top/store/common.UploadFile/uploadOssImage" :data="{
                  directory: 'AGENT_INFO'
                }" :headers="{
  authorization: token
}" :show-file-list="false" :on-success="(res) => handlePicnegativeSuccess(res, 'gift')"
                  :before-upload="beforeAvatarUpload" style="margin-left:20px">
                  <el-image :src="goodsGift.goods_pic" v-if="goodsGift.goods_pic" style="width:100px;height:100px">
                  </el-image>
                  <el-image :src="positive" v-else style="width:100px;height:100px"></el-image>
                </el-upload>
                <div class="gift-input">
                  <div class="gift-item">
                    <span>礼品名称</span>
                    <el-input type="text" class="input" v-model="goodsGift.goods_name"></el-input>
                  </div>
                  <div class="gift-item">
                    <span>礼品价值</span>
                    <el-input type="text" class="input" v-model="goodsGift.goods_price"></el-input>
                  </div>
                </div>
              </div>
            </el-form-item>

            <div style="display:flex;align-items:center;justify-content:flex-start">
              <el-form-item prop="validTime">
                <span slot="label">券有效期</span>
                <el-date-picker v-model="form.validTime" type="datetimerange" start-placeholder="开始日期"
                  format="yyyy-MM-dd HH:mm:ss" :unlink-panels="true" value-format="yyyy-MM-dd HH:mm:ss"
                  end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
            </div>

            <el-form-item>
              <span slot="label"><i class="red">*</i>使用时间</span>

              <div class="flex align-items mb-30">
                <el-radio v-model="form.use_time_seq" :label="10" @change="() => changeUseTimeSeq(form.use_time_seq)">
                  {{ " " }}</el-radio>
                <el-form-item prop="useTime">
                  <el-date-picker :disabled="form.use_time_seq == 20" v-model="form.useTime" type="datetimerange"
                    start-placeholder="开始日期" format="yyyy-MM-dd HH:mm:ss" :unlink-panels="true"
                    value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="flex align-items mb-10">
                <el-radio v-model="form.use_time_seq" :label="20" @change="() => changeUseTimeSeq(form.use_time_seq)">
                  领券后立即生效,有效期</el-radio>
                <el-form-item prop="days" style="margin-left:-20px">
                  <el-input-number v-model="form.days" :min="0" :disabled="form.use_time_seq == 10" :controls="false">
                  </el-input-number>
                </el-form-item>
                <span>天</span>
              </div>

              <!-- <div class="flex align-items">
                  <el-radio  :label="20" @change="() => changeUseTimeSeq(form.use_time_seq)">
                    领券后</el-radio>
                  <el-form-item prop="days" style="margin-left:-20px">
                    <el-input-number v-model="form.days" :min="0" :disabled="form.use_time_seq == 10" :controls="false">
                    </el-input-number><span class="ml-10">天生效,</span>
                  </el-form-item>
                  <el-form-item >
                     <span>有效期</span> <el-input-number v-model="form.days" :min="0" :disabled="form.use_time_seq == 10" :controls="false"></el-input-number>天
                  </el-form-item>
                 </div> -->
            </el-form-item>
            <el-form-item prop='issue_total'>
              <span slot="label">发行总量</span>
              <el-row>
                <el-col :span="5">
                  <el-input v-model.trim="form.issue_total" :disabled="form.issue_total == 0" :controls="false">
                  </el-input>
                </el-col>
                <el-col :span="3" style="margin-left:10px">
                  <el-checkbox v-model="form.issue_total" :true-label="0" :false-label="1">不限【活动长期有效下可选不限】</el-checkbox>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item>
              <span slot="label">使用说明</span>
              <el-row>
                <el-col :span="10">
                  <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" v-model="form.specification">
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
          </div>
          <div>
            <div>适用对象</div>
            <el-divider></el-divider>
            <el-form-item prop="hierarchy_ids">
              <span slot="label"> <i class="red">*</i> 适用门店 </span>
              <div>
                <el-radio v-model="allshop" @change="clearAllshop" :label="true">全部门店</el-radio>
                <el-radio v-model="allshop" @change="clearAllshop" :label="false"> 指定门店</el-radio>
              </div>
              <div class="all-shops" v-if="!allshop">
                <el-checkbox :indeterminate="isIndeterminateshop" v-model="checkShopAll" class="all"
                  @change="checkAllShop">全选</el-checkbox>
                <el-checkbox-group v-model="form.hierarchy_ids" @change="hangleChangeShop">
                  <el-checkbox :label="item.hierarchy_id" v-if="item.hierarchy_id != ''" v-for="item in shopList"
                    :key="item.hierarchy_id">{{ item.hierarchy_name }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>

            <el-form-item>
              <span slot="label"><i class="red">*</i>适用商品</span>
              <el-radio v-model="form.is_all_goods" @change="clearAllGoods" :label="1">全部商品</el-radio>
              <el-radio v-model="form.is_all_goods" @change="clearAllGoods" :label="0">指定商品</el-radio>
            </el-form-item>

            <div v-if="!form.is_all_goods">
              <el-form-item>
                <el-radio v-model="allProtype" @change="clearAllshop" :label="true">全品类</el-radio>
                <el-radio v-model="allProtype" @change="clearAllshop" :label="false"> 指定品类</el-radio>
              </el-form-item>
              <el-form-item prop="attr_value_ids" v-if="!allProtype">
                <span slot="label">适用类别</span>
                <div class="checkbox-list">
                  <el-checkbox :indeterminate="isIndeterminatetype" v-model="checkTypeAll" class="all"
                    @change="checkAllType">全选</el-checkbox>
                  <el-checkbox-group @change="hangleChangeType" style="margin-top:0px;margin-left:20px"
                    v-model="form.attr_value_ids">
                    <el-checkbox v-for="item in protypeList" :label="item.goods_type_id" :key="item.goods_type_id">{{
                        item.goods_type_name
                    }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </div>

            <div v-if="!form.is_all_goods">
              <el-form-item>
                <el-radio v-model="allBrand" @change="clearAllshop" :label="true">全品牌</el-radio>
                <el-radio v-model="allBrand" @change="clearAllshop" :label="false"> 指定品牌</el-radio>
              </el-form-item>
              <el-form-item v-if="!allBrand">
                <span slot="label">适用品牌</span>
                <div class="checkbox-list">
                  <el-checkbox :indeterminate="isIndeterminatebrand" class="all" @change="checkAllBrand"
                    v-model="checkBrand">全选</el-checkbox>
                  <el-checkbox-group @change="hangleChangeBrand" style="margin-top:0px;margin-left:20px"
                    v-model="form.brand_ids">
                    <el-checkbox v-for="item in brandList" :key="item.brand_id" :label="item.brand_id">{{
                        item.brand_name
                    }}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </div>
          </div>
          <div>
            <div>其他限制条件</div>
            <el-divider></el-divider>
            <el-form-item>
              <span slot="label">
                其他限制条件
              </span>
              <el-checkbox v-if="form.type_seq != 40" v-model="form.is_give_limit_using" :true-label="1"
                :false-label="0">
                订单使用优惠券不再赠送
              </el-checkbox>
              <el-checkbox v-if="form.type_seq != 40" v-model="form.is_original_price" :true-label="1" :false-label="0">
                优惠券仅原价购买时使用</el-checkbox>
              <el-checkbox v-if="form.category_seq != 20" v-model="form.is_give_others" :true-label="1"
                :false-label="0">
                优惠券允许转增他人</el-checkbox>
              <el-checkbox :disabled="form.category_seq==20" v-if="form.category_seq == 20" v-model="form.is_give_others_and_not_use" :true-label="1"
                :false-label="0">优惠券只允许转增他人</el-checkbox>

            </el-form-item>
            <el-form-item v-if="form.type_seq != 20 && form.type_seq != 40">
              <span slot="label">叠加使用</span>
              <el-checkbox v-model="form.is_superposition" :true-label="1" :false-label="0">同优惠类型叠加</el-checkbox>
              <el-input class="input" v-if="form.is_superposition == 1" v-model="form.superposition_num"></el-input>
              <span class="mr-10" v-if="form.is_superposition == 1">张</span>

            </el-form-item>
          </div>
          <!-- <el-form-item>
            <el-button type="default" @click="handleClose" v-if="optretion_type !== 'view'">返回</el-button>
            <el-button type="primary" @click="handleSave" v-if="optretion_type !== 'view'">保存</el-button>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
    <div class="bottom">
      <el-button type="default" @click="handleClose" v-if="optretion_type !== 'view'">返回</el-button>
      <el-button type="primary" @click="handleSave" v-if="optretion_type !== 'view'">保存</el-button>
    </div>

  </div>
</template>
<script>

import Cookies from "js-cookie";
import storage from "good-storage";

import notic from "@/assets/images/nopic.png";

import { createEditCoupon } from "@/api/marketing/couponList/index";

import _ from "lodash"
export default {
  name: "addCoupon",
  props: {
    optretion_type: {
      type: String,
      default: "add"
    },
    show: {
      type: Boolean,
      default: false
    },
    couponItem: {
      type: Object,
      default: () => {
        return {};
      }
    },

    sendPassParmas: {
      type: Object,
      default: () => {
        return {}
      }
    },

    shopList: {
      type: Array,
      default() { return [] }
    },
    protypeList: {
      type: Array,
      default() {
        return []
      }
    },
    brandList: {
      type: Array,
      default() {
        return []
      }
    }
  },

  created() {
    this.token = storage.session.get("token") || Cookies.get("token");
  },
  data() {
    const checkedUseThreshold = (rule, value, callback) => {
      if (this.user_threshold == 0) { return callback(); }
      if (this.user_threshold == 1) {
        if (this.form.use_threshold) {
          return callback();
        } else {
          return callback(new Error('订单满天数必填'));
        }
      }
    }

    const checkedDays = (rule, value, callback) => {
      if (this.form.use_time_seq == 20) {
        if (value == '' || !Number(value)) {
          return callback(new Error("天数必填且为数字字符串"));
        } else {
          return callback();
        }
      } else {
        return callback();
      }
    }


    const checkSeqTime=(rule,value,callback)=>{
        if(this.form.use_time_seq==10){
          if(value===null){
            return callback(new Error('使用时间必选'))
          }else{
            return callback();
          }
        }else{
          return callback()
        }
    }

    const checkedTypeData = (rule, value, callback) => {
      if (this.form.type_seq == 10 || this.form.type_seq == 20) {
        if (!value) {
          return callback(new Error('折扣或券面值必选'));
        }
        return callback()
      } else {
        return callback();
      }
    }

    const checkedHierarchyIds = (rule, value, callback) => {
      if (!this.allshop) {
        if (this.form.hierarchy_ids.length == 0) {
          return callback(new Error("门店至少选一个"));
        } else {
          return callback();
        }
      } else {
        return callback()
      }
    }

    const checkedAttrValueIds = (rule, value, callback) => {
      if (this.form.is_all_goods == 0) {
        if (this.form.attr_value_ids.length == 0) {
          return callback(new Error('至少选一个分类'))
        } else {
          return callback();
        }
      } else {
        return callback();
      }
    }

    const checkedBrandIds = (rule, value, callback) => {
      if (this.form.is_all_goods == 0) {
        if (this.form.brand_ids.length == 0) {
          return callback(new Error('至少选一个品牌'))
        } else {
          return callback();
        }
      } else {
        return callback();
      }
    }




    return {
      iphoneImg: require("@/assets/images/marketing/message/iphone.png"),
      form: {
        category_seq: 10,
        title_name: "",
        color_style: "",
        tag_name: "",
        use_threshold: "",
        type_data: "",
        hierarchy_ids: [], //使用门店全选和不选，
        give_rules: [],
        attr_value_ids: [],
        brand_ids: [],
        is_all_goods: 1,
        use_time_seq: 10,
        type_seq: 10,
        days: '',

        background_image: '',

        validTime: null,//券有效时间
        useTime: null //券使用时间
      },
      // 创建优惠券的验证规则
      rules: {
        color_style: [{ required: true, message: "券样式必选", trigger: "change" }],
        title_name: [{ required: true, message: "券名称必填", trigger: 'blur' }],
        tag_name: [{ required: true, message: '请输入券标', trigger: 'blur' }],
        use_threshold: [{ validator: checkedUseThreshold, trigger: "blur" }],
        issue_total: [{ required: true, message: '发行总量必填', trigger: "blur" }],
        days: [{ validator: checkedDays, trigger: "blur" }],
        type_data: [{ validator: checkedTypeData, trigger: "blur" }],
        // valid_start_time: [{ required: true, message: "券开始有效日期必选", trigger: "blur" }],
        // valid_end_time: [{ required: true, message: "券结束有效日期必选", trigger: "blur" }],
        validTime: [{ required: true, message: "券有效日期必选", trigger: "change" }],
        useTime: [
            {validator:checkSeqTime,trigger:'change'}
        ],

        // use_start_time: [{ required: true, message: '券使用开始日期必选', trigger: "blur" }],
        // use_end_time: [{ required: true, message: '券使用结束日期必选', trigger: "blur" }],
        hierarchy_ids: [{ validator: checkedHierarchyIds }],
        attr_value_ids: [{ validator: checkedAttrValueIds }],
        brand_ids: [{ validator: checkedBrandIds }]
      },

      // 选择40时数据类型
      goodsGift: {
        goods_name: "",
        goods_pic: "",
        goods_price: ""
      },

      goods_list: [{
        min_value: '',
        max_value: "",
        probability: ''
      }],


      activeColor: "",

      num: "",

      dialogVisible: false,
      dialogVisibleTitle: "",

      ativeComponent: "",

      // 临时存储变量
      user_threshold: 0,

      allshop: true,
      allBrand: true,
      allProtype: true,

      // 门店品牌和类别状态维护
      isIndeterminateshop: false,
      isIndeterminatetype: false,
      isIndeterminatebrand: false,
      checkShopAll: false,
      checkTypeAll: false,
      checkBrand: false,

      token: '',
      picture: '',
      positive: notic,
    };
  },

  watch: {
    show: {
      immediate: true,
      deep: true,
      handler(val, old) {
        if (val && this.couponItem.coupon_id) {
          this.handleEdits(this.couponItem);
        }
      }
    }
  },

  methods: {
    changeCategory(){
      if(this.form.category_seq==20){
        this.$set(this.form,'is_give_others_and_not_use',1)
      }
    },

    handleEdits(val) {
      this.form = { ...this.form, ...val }
      if (this.form.use_threshold == 0) {
        this.user_threshold = 0;
      } else {
        this.user_threshold = 1;
      }

      if (this.form.type_seq == 30) {
        this.goods_list = _.cloneDeep(this.form.type_data);
      } else if (this.form.type_seq == 40) {
        this.goodsGift.goods_name = this.form.type_data.goods_name;
        this.goodsGift.goods_pic = this.form.type_data.goods_pic;
        this.goodsGift.goods_price = this.form.type_data.goods_price;
      }

      this.form.hierarchy_ids = _.cloneDeep(this.form.hierarchy_ids).map(item => { return item + '' });

      if (this.form.hierarchy_ids && this.form.hierarchy_ids.length > 0) {
        this.allshop = false;
      } else {
        this.allshop = true;
      }

      if (this.form.brand_ids && this.form.brand_ids.length > 0) {
        this.allBrand = false;
      } else {
        this.allBrand = true;
      }

      if (this.form.attr_value_ids && this.form.attr_value_ids.length > 0) {
        this.allProtype = false;
      } else {
        this.allProtype = true;
      }

      this.hangleChangeShop(this.form.hierarchy_ids);

      this.hangleChangeType(this.form.attr_value_ids);

      this.hangleChangeBrand(this.form.brand_ids);


      if (this.form.valid_start_time && this.form.valid_end_time) {
        this.form.validTime = [this.form.valid_start_time, this.form.valid_end_time];
      }

      if (this.form.use_start_time && this.form.use_end_time) {
        this.form.useTime = [this.form.use_start_time, this.form.use_end_time];
      }
    },

    handlePicnegativeSuccess(res, bg) {
      if (res.code == 1) {
        if (bg == 'gift') {
          this.goodsGift.goods_pic = res.data;
        } else {
          this.form.background_image = res.data
        }
      }
    },

    beforeAvatarUpload() { },


    goBack() {
      this.$emit("onclose");
    },

    handleColor(color) {
      this.form.color_style = color;
    },


    handleClose() {
      this.dialogVisible = false;
    },

    hangleChangeShop(value) {
      let checkedCount = value.length;
      this.checkShopAll = checkedCount == this.shopList.length;
      this.isIndeterminateshop = checkedCount > 0 && checkedCount < this.shopList.length;
      console.log(checkedCount, this.shopList.length);
      console.log(this.isIndeterminateshop, 4566)
    },

    hangleChangeType(value) {
      let checkedCount = value.length;
      this.checkTypeAll = checkedCount == this.protypeList.length;
      this.isIndeterminatetype = checkedCount > 0 && checkedCount < this.protypeList.length;
    },

    hangleChangeBrand(value) {
      let checkedCount = value.length;
      this.checkBrand = checkedCount == this.brandList.length;
      this.isIndeterminatebrand = checkedCount > 0 && checkedCount < this.brandList.length;
    },

    checkAllShop(value) {
      this.checkShopAll = value

      this.form.hierarchy_ids = value ? this.shopList.map(item => item.hierarchy_id) : [];

      this.isIndeterminateshop = this.form.hierarchy_ids.length > 0 && this.form.hierarchy_ids.length < this.shopList.length;
    },

    checkAllType(value) {
      this.checkTypeAll = value;
      this.form.attr_value_ids = value ? this.protypeList.map(item => item.goods_type_id) : []
      this.isIndeterminatetype = this.form.attr_value_ids.length > 0 && this.form.attr_value_ids.length < this.protypeList.length;

    },

    checkAllBrand(value) {
      this.checkBrand = value;
      this.form.brand_ids = value ? this.brandList.map(item => item.brand_id) : []
      this.isIndeterminatebrand = this.form.brand_ids.length > 0 && this.form.brand_ids.length < this.brandList.length;
    },


    handleSave() {
      if (this.user_threshold == 0) {
        this.form.use_threshold = 0;
      }

      if (this.allProtype) {
        this.form.attr_value_ids = [];
      }
      if (this.allBrand) {
        this.form.brand_ids = [];
      }

      if (this.allshop) {
        this.form.hierarchy_ids = []
      }

      if (this.form.validTime) {
        this.form.valid_start_time = this.form.validTime[0];
        this.form.valid_end_time = this.form.validTime[1]
      } else {
        this.form.valid_start_time = null
        this.form.valid_end_time = null
      }

      if (this.form.useTime) {
        this.form.use_start_time = this.form.validTime[0];
        this.form.use_end_time = this.form.validTime[1]
      } else {
        this.form.use_start_time = null
        this.form.use_end_time = null
      }

      if (this.form.type_seq == 40) {
        this.form.type_data = this.goodsGift;
      } else if (this.form.type_seq == 30) {
        let val = this.isHasDayu();
        if (val) { return }
        this.form.type_data = _.cloneDeep(this.goods_list);
      }

      this.$refs.form.validate(valite => {
        if (valite) {
          let data = _.cloneDeep(this.form);
          delete data.validTime;
          delete data.useTime;
          createEditCoupon({ ...data }).then(res => {
            if (res.code == 1) {
              if (this.optretion_type == 'add') {
                if (this.sendPassParmas.isCreateCopuon) {
                  this.$router.push({ path: "/marketing/coupon/sendCoupon/index", query: this.sendPassParmas });
                  return
                }
                this.$message.success("创建优惠券成功");
              } else {
                this.$message.success("编辑优惠券成功")
              }
              this.$emit("onclose", true);
            }
            if (res.code == 2) {
              this.$message.error(res.msg);
            }
          })

        }

      })


    },
    handleClose() {
      this.$emit("onclose");
    },
    isHasDayu() {
      let val = this.goods_list.reduce((cur, prev) => {
        return Number(cur.probability) + Number(prev.probability);
      })
      if (val > 100) {
        this.$message.warning("当前随机金额百分比不能大于100");
        return true;
      } else {
        return false;
      }
    },
    handleAdd() {
      let val = this.isHasDayu();
      if (val) { return }
      this.goods_list.push({
        min_value: "",
        max_value: '',
        probability: ''
      });
    },
    handleDelete(index) {
      this.goods_list.splice(index, 1);
    },

    handleThreshold(val) {
      if (val == 0) {
        this.$refs.form.clearValidate(['use_threshold']);
      }
    },

    changeTypeSeq(val) {
      if (val != 10 && val != 20) {
        this.$refs.form.clearValidate(['type_data'])
      }
    },

    clearAllshop() {
      this.$refs.form.clearValidate(['hierarchy_ids'])
    },

    clearAllGoods() {
      this.$refs.form.clearValidate(['attr_value_ids', 'brand_ids'])
    },

    changeUseTimeSeq(type) {
        this.$refs.form.clearValidate(['useTime'])
        this.$refs.form.clearValidate(['days'])
    }

  }
};
</script>

<style lang="less" scoped>
.main {
  margin-top: 10px;
  display: flex;

  .left {
    background: white;
    width: 410px;
    height: calc(100vh - 240px);
    padding: 20px;
    box-sizing: border-box;


    .phone {
      width: 350px;
      min-height: 700px;
      height: 100%;
      background-size: 100% 100%;
      margin: 0 auto;
      padding: 100px 20px 40px 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
    }
  }

  .right {
    background: white;
    overflow-y: auto;
    flex: 1;
    height: calc(100vh - 240px);
    margin-left: 10px;
    padding: 40px 20px;
    box-sizing: border-box;
    position: relative;

    .form {
      margin-top: 32px;
    }

    .head {
      color: #333;
      font-weight: 700;
      text-indent: 10px;
      position: relative;
      font-size: 16px;

      &::before {
        content: "";
        width: 4px;
        height: 16px;
        border-radius: 4px;
        background: #1991ff;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
}

.img-coupon {
  width: 100%;
  overflow: hidden;
}

/deep/ .el-page-header__title {
  color: #565656;
}

/deep/ .el-page-header__content {
  font-size: 16px;
  color: #565656;
}

.top {
  background: white;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 32px;
}

.goods-btn {
  padding: 5px 0;
}

.all-shops {
  display: flex;
  align-items: center;

  .all {
    margin-right: 20px;
  }
}

.bgColor {
  opacity: 0.2;
}

.list {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .precter {
    margin: 0 20px;
    display: flex;
  }
}

.gift {
  display: flex;
  align-items: flex-start;
}

.gift-item {
  display: flex;
  margin-bottom: 20px;
}

.gift-input {
  margin-left: 20px;
}

.input {
  width: 200px;
  margin-left: 20px;
}

.checkbox-list {
  display: flex;
  align-items: flex-start;
  margin-left: 10px;
}

.red {
  color: red;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.icon {
  font-size: 30px;

  color: #fff
}

.bottom {
  display: flex;
  margin-left: 500px;
  padding: 10px 0 0 20px;
}


.flex {
  display: flex;
}

.justify-content {
  justify-content: center;
}

.align-items {
  align-items: center;
}

.mb-30 {
  margin-bottom: 30px;
}

.input-300 {
  width: 300px;
}


.el-upload {
  height: 50px;
}

.phone-bg {
  display: flex;
  height: 100px;
  width: 100%;
  margin-left: 10px;
  background-repeat: no-repeat;
}

.phone-money {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bolder;
}

.phone-right {
  flex: 1;
  color: #fff;
  justify-content: space-evenly;
  align-items: flex-start;
  font-size: 12px;

}

.mb-10 {
  margin-bottom: 20px;
}
</style>
